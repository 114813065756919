import React, {
  ReactNode,
  ComponentProps,
  Fragment,
  PropsWithChildren,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { cx } from '../../helpers/utils';
import { FormattedMessage } from 'react-intl';
import { Button } from '../buttons';

const colorClasses: Record<NonNullable<ModalDialogColor>, string> = {
  primary: 'bg-indigo-100',
  success: 'bg-green-100',
  error: 'bg-red-100',
  warning: 'bg-yellow-100',
  info: 'bg-blue-100',
};

type ModalDialogColor = 'primary' | 'success' | 'error' | 'warning' | 'info';

const bgColorClasses: Record<NonNullable<ModalDialogBgColor>, string> = {
  default: 'bg-white',
  raised: 'bg-slate-25',
};

type ModalDialogBgColor = 'default' | 'raised';

export const ModalDialog: React.FC<
  PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    title?: React.ReactNode;
    text?: React.ReactNode;
    icon?: React.ReactNode;
    actions?: React.ReactNode;
    color?: ModalDialogColor;
    bgColor?: ModalDialogBgColor;
    size?: 'small' | 'regular' | 'large' | 'xl';
  }>
> = ({
  open,
  onClose,
  title,
  text,
  icon,
  children,
  actions,
  bgColor = 'default',
  color = 'info',
  size = 'regular',
}) => {
  const cancelButtonRef = useRef(null);

  const sizeClassName = {
    small: 'max-w-96',
    regular: 'max-w-2xl',
    large: 'max-w-5xl',
    xl: 'max-w-7xl',
  }[size];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-ModalDialog"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cx(
                  sizeClassName,
                  bgColorClasses[bgColor],
                  'relative flex-grow transform rounded-dialog text-left transition-all'
                )}
              >
                <div className="flex gap-3 p-6 md:p-8">
                  {icon ? (
                    <div
                      className={cx(
                        colorClasses[color],
                        `mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`
                      )}
                    >
                      {icon}
                    </div>
                  ) : null}
                  <div className="flex w-full flex-col gap-3 text-center sm:text-left">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-semibold leading-6 text-slate-800"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {text && (
                      <div className="text-sm text-slate-500">{text}</div>
                    )}
                    {children ? <div>{children}</div> : null}
                  </div>
                </div>
                {actions && (
                  <div className="flex flex-row-reverse items-center gap-2 rounded-b-dialog px-6 pb-8 md:px-8 md:pb-8">
                    {actions}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

/**
 * A pre-built dialog for confirming some actions
 *
 * @example
 * <ConfirmActionDialog
 *    open={open}
 *    title={<FormattedMessage defaultMessage="Do a thing?" />}
 *    text={<FormattedMessage defaultMessage="Are you sure?" />}
 *    yes={<FormattedMessage defaultMessage="Do it!" />}
 *    yesProps={{ loading: leaveSpaceResult.loading, color: 'error' }}
 *    onYes={handleLeaveSpace}
 *    onNo={() => setOpen(false)}
 * />
 */
export function ConfirmActionDialog(props: {
  title: ReactNode;
  text: ReactNode;

  open?: boolean;

  /** Perform the confirmed action */
  onYes: () => void;

  /** Close the dialog */
  onNo: () => void;

  /** The text of the yes button */
  yes: ReactNode;

  /** Change the no message, defaults to 'Cancel' */
  no?: ReactNode;

  /** Add extra props to the yes button */
  yesProps?: ComponentProps<typeof Button>;

  /** Add extra props to the no button */
  noProps?: ComponentProps<typeof Button>;
}): React.ReactElement {
  const {
    open = false,
    title,
    text,
    yes,
    no = <FormattedMessage defaultMessage="Cancel" id="47FYwb" />,
    yesProps,
    noProps,
    onYes,
    onNo,
  } = props;
  return (
    <ModalDialog
      open={open}
      size="small"
      title={title}
      text={text}
      onClose={onNo}
      actions={
        <>
          <Button {...yesProps} onClick={onYes}>
            {yes}
          </Button>
          <Button variant="naked" {...noProps} onClick={onNo}>
            {no}
          </Button>
        </>
      }
    />
  );
}
