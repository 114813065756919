import React, { useRef, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  arrow as arrowMiddleware,
  FloatingArrow,
  useTransitionStyles,
  Placement,
} from '@floating-ui/react';
import { cx } from '../../helpers/utils';

type TooltipProps = {
  children: React.ReactElement;
  title: React.ReactNode;
  placement?: Placement;
  arrow?: boolean;
  className?: string;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  isDisabled?: boolean;
};

const ARROW_HEIGHT = 6;
const GAP = 2;

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  title,
  placement,
  arrow,
  className,
  isDisabled,
  root,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement ?? 'bottom',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow &&
        arrowMiddleware({
          element: arrowRef,
        }),
    ].filter(Boolean),
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: 'tooltip' });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      {React.cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      {title && (
        <FloatingPortal root={root}>
          {isOpen && isMounted && !isDisabled && (
            <div
              className={cx(
                'z-Tooltip flex max-w-72 items-center rounded-md bg-slate-900/90 px-2 py-1 text-xs text-white shadow-lg',
                className ?? ''
              )}
              ref={refs.setFloating}
              style={{ ...floatingStyles, ...transitionStyles }}
              {...getFloatingProps()}
            >
              <span>{title}</span>
              {arrow && (
                <FloatingArrow
                  height={ARROW_HEIGHT}
                  width={8}
                  ref={arrowRef}
                  context={context}
                  tipRadius={3}
                  className="fill-slate-900/90"
                />
              )}
            </div>
          )}
        </FloatingPortal>
      )}
    </>
  );
};
