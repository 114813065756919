import React from 'react';
import { cx } from '../../helpers/utils';

export type AlertProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  severity: 'error' | 'warning' | 'info' | 'success';
  variant?: 'filled' | 'light';
  action?: React.ReactNode;
  alternateIcon?: React.ReactNode;
  compact?: boolean;
};

type VariantSeverityProps = {
  [A in NonNullable<AlertProps['variant']>]: {
    [B in AlertProps['severity']]: string;
  };
};

const lightWrapper = 'border border-slate-200 bg-white w-full';
const variantWrapperClasses: VariantSeverityProps = {
  light: {
    error: lightWrapper,
    warning: lightWrapper,
    info: lightWrapper,
    success: lightWrapper,
  },
  filled: {
    error: 'bg-red-50 border border-red-200',
    warning: 'bg-yellow-50 border border-yellow-300',
    info: 'bg-blue-50 border border-blue-200',
    success: 'bg-green-50 border border-green-300',
  },
};

const lightIcon = 'p-0.5 w-[5px] self-stretch rounded';
const variantIconClasses: VariantSeverityProps = {
  light: {
    error: cx(lightIcon, 'bg-red-500'),
    warning: cx(lightIcon, 'bg-yellow-500'),
    info: cx(lightIcon, 'bg-indigo-500'),
    success: cx(lightIcon, 'bg-green-500'),
  },
  filled: {
    error: 'text-red-600',
    warning: 'text-yellow-600',
    info: 'text-blue-600',
    success: 'text-green-600',
  },
};

const variantTitleClasses: VariantSeverityProps = {
  light: {
    error: '',
    warning: '',
    info: '',
    success: '',
  },
  filled: {
    error: 'text-red-800',
    warning: 'text-yellow-800',
    info: 'text-blue-800',
    success: 'text-green-800',
  },
};

const variantTextClasses: VariantSeverityProps = {
  light: {
    error: '',
    warning: '',
    info: '',
    success: '',
  },
  filled: {
    error: 'text-red-700',
    warning: 'text-yellow-700',
    info: 'text-blue-700',
    success: 'text-green-700',
  },
};

export const Alert: React.FC<AlertProps> = ({
  title,
  description,
  severity,
  variant = 'filled',
  action,
  alternateIcon,
  compact = false,
}) => {
  let icon = null;
  const iconClasses = cx(
    variantIconClasses[variant][severity],
    'flex-shrink-0 justify-center self-center'
  );

  switch (severity) {
    case 'error':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
      );
      break;
    case 'warning':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
      );
      break;
    case 'info':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      );
      break;
    case 'success':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      );
      break;
  }

  if (alternateIcon) {
    icon = alternateIcon;
  }

  if (variant === 'light') icon = <div />;

  return (
    <div
      className={cx(
        'flex-row items-center rounded-md',
        variantWrapperClasses[variant][severity],
        compact ? 'p-2' : 'p-4'
      )}
    >
      <div className="flex">
        {icon ? (
          <div className={iconClasses} aria-hidden="true">
            {icon}
          </div>
        ) : null}
        <div
          className={cx(
            'flex flex-1 flex-col flex-wrap items-center justify-between gap-2 md:flex-row',
            compact ? 'ml-2' : 'ml-3'
          )}
        >
          <div
            className={cx(
              variantTextClasses[variant][severity],
              'text-sm',
              compact ? 'self-start' : 'self-center'
            )}
          >
            {title && (
              <p
                className={cx(
                  variantTitleClasses[variant][severity],
                  'text-md font-semibold'
                )}
              >
                {title}
              </p>
            )}
            {description && (
              <p className={cx(title ? 'mt-2' : 'text-md')}>{description}</p>
            )}
          </div>
          {action ? (
            <div
              className={cx(
                'flex gap-2',
                compact ? 'ml-0' : 'self-end md:ml-2 md:self-center'
              )}
            >
              {action}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
